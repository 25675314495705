<template>
  <div class="header">
    <div class="headers space_between">
      <div class="header_top" @click="handleindex">
        <img src="@/assets/logo/log.png" class="logo" alt />

      </div>
      <div class="header_nav hidden-sm-and-down">
        <headerNav></headerNav>
      </div>
      <!-- <div @click="hdtc">退出</div> -->
      <!-- <div @click="hd">1 {{ LOGIN_IS_SHOW }}</div> -->
      <!-- <div @click="hd2">2 {{ LOGIN_IS_SHOW }}</div> -->
      <div class="switch-language">
        <el-dropdown @command="changeLanguage">
          <span class="el-dropdown-link">
            {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">Englist</el-dropdown-item>
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header_center">
        <!-- 未登录显示 {{ $t('h.login') }}/{{ $t('h.register') }}-->
        <div v-if="LOGIN_IS_SHOW" @click="handleLog();playAudio()" class="login">{{ $t('h.signIn') }}/{{ $t('h.register') }}</div>
        <!-- 登录成功显示 -->
        <div v-else class="header_center_info" v-show="$route.path!='/home'">
          <!-- 头像 -->
          <div class="header_img">
            <img :src="data.avatar" alt />
          </div>
          <!-- 金币 -->
          <div clas="header_gold">
            <div class="d-c mh20">
              <money class="money"></money>
              {{ data.accountAmount }}
            </div>
            <div class="d-c ih20">
              <integral class="integral"></integral>
              {{ data.accountCredits }}
            </div>
          </div>
          
          <!-- 侧边按钮 -->
          <!-- <div class="sliderbtn" @click="handleOpen()">
            <div :class="sliderbtn == true ? ' aa' :''" style="height: 70px;">
              <div>
                <svg
                  t="1705645689576"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="4383"
                  width="24"
                  height="24"
                >
                  <path
                    d="M746.666667 377.6H277.333333c-23.466667 0-42.666667-19.2-42.666666-42.666667s19.2-42.666667 42.666666-42.666666h469.333334c23.466667 0 42.666667 19.2 42.666666 42.666666s-19.2 42.666667-42.666666 42.666667zM746.666667 554.666667H277.333333c-23.466667 0-42.666667-19.2-42.666666-42.666667s19.2-42.666667 42.666666-42.666667h469.333334c23.466667 0 42.666667 19.2 42.666666 42.666667s-19.2 42.666667-42.666666 42.666667zM746.666667 731.733333H277.333333c-23.466667 0-42.666667-19.2-42.666666-42.666666s19.2-42.666667 42.666666-42.666667h469.333334c23.466667 0 42.666667 19.2 42.666666 42.666667s-19.2 42.666667-42.666666 42.666666z"
                    fill="#727477"
                    p-id="4384"
                  />
                </svg>
              </div>
              <div>
                <svg
                  t="1705647551658"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="4521"
                  width="24"
                  height="24"
                >
                  <path
                    d="M512 162c-193.3 0-350 156.7-350 350s156.7 350 350 350 350-156.7 350-350-156.7-350-350-350z m0 643.3C350 805.3 218.7 674 218.7 512S350 218.7 512 218.7 805.3 350 805.3 512 674 805.3 512 805.3z"
                    fill="#727477"
                    p-id="4522"
                  />
                  <path
                    d="M584.1 393.9L512 466l-72.1-72.1-46 46L466 512l-72.1 72.1 46 46L512 558l72.1 72.1 46-46L558 512l72.1-72.1z"
                    fill="#727477"
                    p-id="4523"
                  />
                </svg>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, logOut } from "@/api/index";
import { removeToken } from "@/api/cookie";
import { mapState } from "vuex";

import store from "@/vuex/store";
import headerNav from "@/components/headerNav.vue";

export default {
  components: {
    headerNav
  },
  data() {
    return {
      user: {},
      sliderbtn: false,
      show: true,
      data: {},
      lang:'Englist'
    };
  },

  computed: {
    ...mapState(["login_top", "LOGIN_IS_SHOW"])
  },
  mounted() {
    if(this.$i18n.locale == 'en') {
      this.lang = "English";
    } else {
      this.lang = "简体中文"
    }
    store.subscribe((mutation, state) => {
      if (mutation.type === "USER_INFO") {
        console.log(mutation);
        console.log(state.USER_INFO);
        this.data = state.USER_INFO;
      }
    });
    this.getInfo();
    this.$bus.$on("islogin", data => {
      this.show = data;
      console.log(data);
      if (data == false) {
        getUserInfo().then(res => {
          this.user = res.data.data;
        });
      }
    });
    this.changestore()
   
  },
  methods: {
    changeLanguage(lang) {
      if(lang == "en") {
        this.lang = "English";
      } else {
        this.lang = "简体中文"
      }
      this.$i18n.locale = lang;
      // 保存到本地缓存
      localStorage.setItem('locale', lang);
    },
    playAudio(){
      this.$store.commit("playAudio");
    },
    changestore(){
      if(this.$store.showstate){
        this.sliderbtn=this.$store.showstate
      }
    },
    hd() {
      this.$store.commit("LOGIN_IS_SHOW", false);
    },
    hd2() {
      this.$store.commit("LOGIN_IS_SHOW", true);
    },
    handleindex() {
      this.$router.push("/");
    },
    hdtc() {
      getUserInfo().then(res => {
        if (res.data.code == 200) {
          this.$socket.send("logout_userId_" + res.data.data.userId);
          logOut().then(res => {
            if (res.data.code == 200) {
              this.$router.push("/");
              this.$bus.$emit("close", true);

              removeToken();
              this.show = true;
            }
          });
        }
      });
    },
    handleOpen() {
      this.sliderbtn = !this.sliderbtn;
      this.$bus.$emit("sliderbtn", this.sliderbtn);
    },
    handleLog() {
      // this.$bus.$emit("close", true);
      this.$router.push('/login')
    },
    getInfo() {
      getUserInfo().then(res => {
        console.log('获取用户数据',res);
        this.user = res.data.data;
        if (res.data.code == 200) {
          this.$store.commit("USER_INFO", res.data.data);
          store.subscribe((mutation, state) => {
            if (mutation.type === "USER_INFO") {
              console.log(mutation);
              console.log(state.USER_INFO);
              this.data = state.USER_INFO;
            }
          });
        } else {
          this.$store.commit("LOGIN_IS_SHOW", true);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 210px;
  height: 56px;
  @media (max-width: 768px) {
    width: 140px;
    height: 37px;
  }
}
.login {
  width: 147px;
  height: 39px;
  background: linear-gradient(
    90deg,
    rgba(58, 151, 228, 0.3) 0%,
    rgba(37, 244, 132, 0.3) 100%
  );
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgba(37.00000159442425, 244.0000006556511, 132.0000073313713, 1),
      rgba(58.00000034272671, 151.00000619888306, 228.0000016093254, 1)
    )
    1 1;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 85px;
    height: 38px;
    font-size: 13px;
  }
}
.aa {
  transition: all 0.4s linear;

  transform: translateY(-50%);
}
.bb {
  transition: all 0.4s linear;

  transform: translateY(-50%);
}
.header_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 550px) {
    height: 35px;
    width: 35px;
  }
}
.header {
  height: 84px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 550px) {
    height: 60px;
  }
  .headers {
    width: 96%;
    margin: 0 auto;
    height: 100%;
    position: relative;
    .header_center_info {
      display: flex;
      align-items: center;
    }
    /* .sliderbtn {
      width: 35px;
      height: 35px;
      padding-top: 7px 0 0 0;
      overflow: hidden;
      div {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
      }
    } */
  }
}
.header_nav {
  width: 50%;
}
.header::before {
  content: ""; /*必须设置content属性*/
  position: absolute;
  width: 100%;
  height: 143px;
  background: linear-gradient(
    -204deg,
    rgba(37, 244, 132, 0.3) 0%,
    rgba(32, 29, 48, 0) 40%,
    rgba(248, 125, 81, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
}
.main_I_II {
    width: 112px;
    height: 42px;
    margin-left: 10px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    @media (max-width: 550px) {
      width: 80px;

      font-size: 13px;
      height: 30px;
    }
  }
  .switch-language {
    color: #fff;
    .el-dropdown-link {
      color: #fff;
    }
  }
</style>