<template>
  <div>
    <marquee :scrollamount="scrollamount" class="notice-bar" width="100%" behavior="scroll">
      <div v-html="text"></div>
    </marquee>
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
      <ul class="ul-item">
        <li class="li-item" v-for="(item, index) in listData" :key="index">
          <div :style="{'background': `url(${item.ornamentLevelImg})`}" class="back">
            <!-- {{ item.ornamentLevelImg }} -->
            <img :src="item.imageUrl" class="itemImg"/>
            <p class="textover-f">{{ item.itemName }}</p>
          </div>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getWeapon, getArticle } from "@/api/index";

export default {
  name: "Example03Basic",

  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      timer: null,
      data:{
        page:1,
        size:15,
        orderByFie:0,
        status:[0,2,3,5],
        source:[1,2,3,4],
        // ornamentLevelIds:[1]
      },
      scrollamount: 6,
      listData: [],
      text: null,
      classOption: {
        limitMoveNum:2,
        direction: 3
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeCreate() {
    clearInterval(this.timer);
  },
  created() {
    this.getList();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      // getWeapon().then(res => {
      //   console.log(res);
      //   this.listData = res.data.rows;
      // });
    }, 5000);
  },
  methods: {
    getList() {
      getWeapon(this.data).then(res => {
        this.listData = res.data.data;
        console.log('this.listData',this.listData);
      });
      getArticle("gg").then(res => {
        this.text = res.data.data.contentList[0].content.replace(/<[^>]*>/g, '');
        console.log(res);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.itemImg{
width: 80px;
height: 80px;
}
@media (max-width: 550px) {
  .warp {
    height: 80px !important;
  }
  .li-item {
    width: 75px !important;
    height: 80px !important;
    font-size: 7px;
    img {
      width: 60px !important;
      height: 60px !important;
    }
  }
}
.notice-bar {
  width: 100%;
  margin: 0 auto;
  position: relative;
  width: 98%;
  height: 32px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  overflow-y: auto;
  div {
    height: 32px;
  }
}
.warp {
  width: 100%;
  height: 112px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      
      display: flex;
      .li-item {
        width: 105px;
        height: 112px;
        margin-left: 10px;
        // background-color: #999;
        color: #fff;
        text-align: center;
        .back {
          padding: 4px;
          background-size: 100% 100% !important;
        }
        p {
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
