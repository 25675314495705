import Vue from "vue";
import VueI18n from "vue-i18n";

import en from './langs/en';
import zh from  './langs/zh';

import ElementLocale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from  'element-ui/lib/locale/lang/zh-CN';

ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "zh", // 从localStorage中获取 默认英文
  messages: {
    'zh': {
      ...zh,
      ...zhLocale
    },
    'en': {
      ...en,
      ...enLocale
    }
  },
  silentTranslationWarn: true,
  fallbackLocale: 'zh',
  globalInjection: true
});
 
export default i18n;