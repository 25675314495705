// 简体中文语言包
export default {
    h:{
        lang: "简体中文",
        home:"首页",
        welfare:"福利",
        skinsMall:"商城",
        topup:"充值",
        account: "我的",
        login:"登录",
        register:"注册",
        signIn:"登录",
        signUp:"注册",
        prvacyPolicy:"隐私协议",
        aboutItemTakes:"关于我们",
        support:"帮助中心",
        userAgreement:"用户协议",
        steamTradeUrl:"交易链接更换绑定",
        aboutTakeItems:"饰品取回相关",
        termsAndConditions:"条款和条例",
        battles:"对战",
        roll:"ROLL房",
        percent:"幸运开箱",
        Login:"登录",
        accountNumber:"请输入账号",
        password:"请输入密码",
        forgotPassword:"忘记密码",
        agreeTerms:"我已满18岁，并阅读和同意《用户协议》、《隐私政策》和《反洗钱协议》，承诺理性消费。",
        accountRegister:"没有账号?立即注册",
        phoneMumber:"请输入手机号",
        nickname:"请输入昵称",
        confirmPassword:"请输入确认密码",
        code:"请输入验证码",
        invitationCode:'请输入邀请码',
        playWithBlindBoxes: "玩转盲盒",
        rapidCompetition: "急速竞技",
        pursuingDreamsAndMovingForward: "逐梦前行",
        gameplayDescription: "玩法说明",
        accountBalance: "账户余额",
        recharge: "充值",
        loginExpired: "登录过期，请重新登陆",
        loading: "加载中",
        waitBeforeTry: "请勿频繁操作",
        reLogin: "登录已过期，请重新登录",
        tooFrequent: "您的操作太频繁，请1秒后再试",
        logIn: "立即登录",
        accountUpdate: "账户修改",
        noAccount: "没有账号？",
        signup: "立即注册",
        getCode: "获取验证码",
        newPassword: "请输入新的密码",
        confirm: "确认",
        haveAccout: "已有账号？",
        signin: "去登录",
        resend: "重新获取",
        pleaseEnterNewPasswordOrPhoneNumber: "请输入新密码或手机号",
        passwordsNotMatch: "密码输入不一致，请重新输入！",
        resetPassword: "密码重置成功",
        incorrectVerificationCode: "验证码错误，请重试",
        usernameOrPhonenumber: "请输入用户名或手机号",
        agreeUserAgreement: "请点击同意用户协议",
        notMatchPasswords: "两次密码不一致",
        pleaseGetCode: "请获取验证码",
        registrationSucceeded: "注册成功",
        followingAgreements: "请先勾选以下协议",
        usernameOrPassword: "请输入用户名或密码",
        loginSuccessful: "登录成功",
        myBag: "我的背包",
        identityVerification: "实名认证",
        completeRealName: "请先进行实名认证",
        identityVerificationSuccessful: "实名认证成功",
        clickVerify: "您还未实名认证，点击立即进行实名认证",
        verificationCompleted: "已实名认证",
        personalDetails: "个人明细",
        steamTrading: "您还未绑定STEAM交易链接",
        boundSteamTrading: "绑定STEAM交易链接",
        pleaseSteamTrading: "请输入STEAM交易链接",
        steamTradingSuccess: "STEAM交易链接绑定成功",
        steamTradingFail: "STEAM交易链接绑定失败",
        bound: "已绑定",
        notBound: "未绑定",
        gotobind: "去绑定",
        gotomodify: "去修改",
        signInPassword: "登录密码",
        myInviteCode: "我的邀请码",
        bindInvitationCode: "绑定邀请码",
        signOut:"退出登录",
        cancel: "取消",
        confirmOk: "确定",
        realname:"请输入您的真实姓名",
        idCard: "请输入您的身份证号码",
        passwordModification: "密码修改",
        oldPassword: "请输入旧密码",
        pleaseNewPassword: "请输入新的密码",
        passwordTips: "密码长度必须在5到20个字符之间",
        yourInvitationCode: "请输入您的邀请码",
        invitationTips: "邀请码仅可绑定一次，请谨慎操作",
        dailyCheckIn: "每日签到",
        dailyTips: "每日可签到1次，随机获得0.1-5币",
        checkIn: "签到",
        signOutTips: "确定要退出登录吗？",
        chooseAvatar: "选择头像",
        vipUpgradeCriteria: "VIP升级标准",
        vipLevel: "VIP等级",
        modificationSuccessful: "修改成功",
        invitationCodeBindingSuccessful: "绑定邀请码成功",
        passwordChangeTips: "密码修改成功，请重新登陆",
        passwordChangeErrorTips: "两次密码输入不一致，请重新输入",
        goldenListAchievement: "金榜题名",
        welfareCenter: "福利中心",
        promotionalBenefits: "推广福利",
        spendingLeaderboard: "消费排行榜",
        today: "今天”",
        yesterday: "昨天",
        lastSevenDays: "近七日",
        explanation: "说明",
        rewardsExplanation: "排行榜奖励说明",
        rank: "名次",
        reward: "奖励",
        rewardTips:"当日消费奖励排名前10名玩家，次日【00：00】",
        rewardTips2: "系统自动发送消费奖励到玩家账户",
        ranking: "排名",
        player: "玩家",
        spendOCoins: "消费O币",
        spendGCoins: "消费G币",
        total: "总和",
        yetRanked: "您还未上榜",
        yourRank: "您的排名",
        shippingLeaderboard: "出货排行榜",
        itemImage: "物品图片",
        itemName: "物品名称",
        itemValue: "物品价值",
        promotionEarnings: "推广收益",
        tomorrowEstimate: "明日预计",
        myTeam: "我的团队",
        view: "查看",
        myInviter: "我的邀请者",
        earningsDetails: "收益明细",
        time: "时间",
        type: "类型",
        earnings: "收益",
        Nickname: "昵称",
        amount: "金额",
        todaySpending: "今日消费",
        yesterdaySpending: "昨日消费",
        noMoreAvailable: "暂无更多",
        back: "返回",
        retrieveTutorial: "取回教程",
        websiteRechargeTutorial: "网站充值教程",
        changeAvatar: "修改头像",
        changeNickname: "修改昵称",
        changePhoneNumber: "修改手机号",
        selectFile: "选择文件",
        sendVerificationCode: "发送验证码",
        validPhoneNumber: "请输入正确的手机号",
        totalNumberOfCosmetics: "饰品总数",
        totalValueOfCosmetics: "饰品总价值",
        totalNumberOfSelectedCosmetics: "已选饰品总数",
        totalValueOfSelectedCosmetics: "已选饰品总价值",
        defaultSorting: "默认排序",
        lowToHigh: "按价格从低到高排序",
        highToLow: "按价格从高到低排序",
        pleaseCosmeticName: "请输入饰品名称",
        extract: "提取",
        decompose: "分解",
        selectAll: "全选",
        selected: "已选",
        backpackTips: "确定清空背包吗？",
        decomposeTips: "确定要分解吗？",
        extractBackpackTips: "确定提取背包内所有物品吗？",
        extractWantTips: "确定要提取",
        extractWantTimes: "件物品吗？",
        cosmeticBeforeProceeding: "请先选择饰品再操作",
        decompositionSuccessful: "分解成功",
        pickupInvoice: "提货账单",
        incomeAndExpenditureDetails: "收支明细",
        rechargeDetails: "充值明细",
        decompositionDetails: "分解明细",
        extractionComplete: "提取完成",
        decomposed: "已分解",
        frozen: "冻结中",
        applyForPickup: "申请提货",
        awaitingShipment: "等待收货",
        shippingInProgress: "正在发货",
        coin: "币",
        purpose: "用途",
        status: "状态",
        consumption:"消费",
        income: "收入",
        other: "其他",
        paymentAmount: "支付金额",
        paymentMethod: "支付渠道",
        paymentOrderNumber: "支付单号",
        paymentTime: "支付时间",
        cardPin: "卡密",
        alipay: "支付宝",
        weChat: "微信",
        redemptionInstructions: "兑换说明",
        yuan: "元",
        pleaseCardPin: "请输入卡密",
        payment: "支付",
        alipayTips:"订单生成后,请用支付宝描二维码进行支付",
        payMoneyTips: "请选择金额，再进行支付",
        agreeContentTips: "请先同意《用户协议》，《隐私政策》等内容",
        rewardsStore: "积分商城",
        comprehensiveSorting: "综合排序",
        priceInDescendingOrder: "价格降序",
        priceInAscendingOrder: "价格升序",
        valueFiltering: "价值筛选",
        PointsRedemption: "积分兑换",
        exchange: "兑换",
        category:"类别",
        quality:"品质",
        redemptionSuccessful: "兑换成功",
        default: "默认",
        noCosmeticsAvailable: "暂无饰品",
        allLoaded: "全部加载完毕",
        latest: "最新",
        blindBoxCategory: "盲盒类别",
        battleLobby: "对战大厅",
        luckyKing: "欧皇",
        unluckyChief: "非酋",
        createRoom: "创建房间",
        inProgress: "进行中",
        ended:"已结束",
        myParticipation: "我参与的",
        history: "历史记录",
        pending: "等待中",
        numberOfRounds: "回合数",
        enter: "进入",
        spectate: "观战",
        createSimilarRoom: "创建相同房间",
        copyShareLink: "复制分享链接",
        waitingJoin: "等待加入",
        consumeTips: "确定消耗",
        join: "加入",
        create: "创建",
        createdSuccessfully:"创建成功",
        gameStart: "游戏开始",
        copySuccessful: "复制成功",
        browserTips: "浏览器版本过低，请升级浏览器",
        informationTips: "获取用户信息失败，请重新登录",
        official: "官方",
        streamer: "主播",
        pleaseRollTips: "请输入ROLL房名称",
        viewRoom:"查看房间",
        joinRoom:"加入房间",
        noMoreAvailableRoll: "暂无更多Roll房",
        pullDown: "下拉加载更多",
        favoriteCosmetic: "选择心仪饰品",
        dreamChasing: "追梦成功率",
        randomlyObtain:"随机得到饰品",
        pleaseSelectCosmetic: "请选择饰品",
        cost: "花费",
        tryYourLuck: "试试运气",
        priceRange: "价格区间",
        minimumValue: "最小值",
        maximumValue: "最大值",
        filter: "筛选",
        congratulationsWinning: "恭喜获得",
        historicalDrop: "历史掉落",
        streamerDrop: "主播掉落",
        obtain: "获得",
        value: "价值",
        result: "结果",
        successRate: "成功率",
        success: "成功",
        failure: "失败",
        rechargeTips: "余额不足，请充值",
        failedTips: "失败啦，啥都没得到~",
        cosmeticFirstTips: "请先选择饰品",
        gameplayInstructions: "玩法说明",
        iKnow: "我知道了",
        copyright: "版权所有",
        participationRequirements: "参与条件",
        allUsersCanParticipate: "所有用户均可参与",
        rechargeSinceOpeningDate:"自开启之日起充值",
        participate: "即可参与",
        roomDescription: "房间简介",
        noneAtTheMoment: "暂无",
        drawTime: "开奖时间",
        prizePool: "奖池",
        listOfWinners: "获奖名单",
        participants: "参与人员",
        breakDownItem: "分解物品",
        putIntoBackpack: "放入背包",
        breakDownAll: "全部分解",
        memberDrop: "会员掉落",
        boxContains: "盒子包含",
        openImmediately: "立即开启",
        pleaseEnterROLLRoomPassword: "请输入ROLL房密码",
        joinedSuccessfully: "加入成功请等待开奖",
        redeemRedPacket: "兑换红包",
        enterCdk: "请输入CDK",
        claimedSuccessfully: "领取成功",
        congratulationsObtaining: "恭喜获得",
        downloadAPP: "下载APP",
        android: "安卓",
        siteMessage: "站内信",
        allRead: "全部已读",
        deleteAll: "全部删除",
        kindReminder: "温馨提示",
        confirmAction: "确定进行操作吗",
        numberPeopleRoom: "房间人数",
        roomCategory: "房间类别",
        mode: "模式",
        typesBlindBoxes: "盲盒种类",
        valueBlindBox: "盲盒价值",
        maximum6: "最多选择6种盲盒",
        maximumLimit: "选择数量超出最大限制",
    },
    b: {
        "隐私协议":"隐私协议",
        "关于我们":"关于我们",
        "帮助中心":"帮助中心",
        "用户协议":"用户协议",
        "交易链接更换绑定":"交易链接更换绑定",
        "饰品取回相关":"饰品取回相关",
        "条款和条例":"条款和条例",
        "交易链接更换解绑定":"交易链接更换解绑定",
        "合规声明":"合规声明",
        "充值相关": "充值相关",
        "邀请码相关":"邀请码相关",
        "匕首":"匕首",
        "手枪":"手枪",
        "步枪":"步枪",
        "微型冲锋枪":"微型冲锋枪",
        "重型武器":"重型武器",
        "手套":"手套",
        "印花":"印花",
        "其他":"其他",
        "默认":"默认",
        "StatTrak™":"StatTrak™",
        "★":"★",
        "★ StatTrak™":"★ StatTrak™",
        "普通":"普通",
        "闪耀":"闪耀",
        "金色":"金色",
        "全息":"全息",
        "闪亮":"闪亮",
        "纪念品":"纪念品",
        "欢迎来到CSGOWE、本网站禁止向未成年人提供服务！": "欢迎来到CSGOWE、本网站禁止向未成年人提供服务！"
    }
    
    
}