<template>
  <div class="hidden-md-and-up">
    <div class="nav" @click="playAudio">
      <router-link to="/">
        <div class="yuan">
          <div class="btn_icon">
            <svg
              t="1705648659062"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4660"
              width="100%"
              height="100%"
            >
              <path
                d="M469.504 76.5952a87.38816 87.38816 0 0 1 98.87744 0.34816l303.22688 209.6128a87.38816 87.38816 0 0 1 37.6832 71.8848V839.68a87.38816 87.38816 0 0 1-87.36768 87.38816H210.26816A87.38816 87.38816 0 0 1 122.88 839.65952V358.83008c0-28.89728 14.29504-55.95136 38.1952-72.21248L469.504 76.5952z m221.34784 631.1936H341.34016a43.68384 43.68384 0 0 0-43.70432 43.68384v1.00352c0 24.12544 19.5584 43.68384 43.70432 43.68384h349.51168a43.68384 43.68384 0 0 0 43.70432-43.68384v-1.024a43.68384 43.68384 0 0 0-43.70432-43.68384z"
                fill="#727477"
                p-id="4661"
              />
            </svg>
          </div>
          <div>{{ $t('h.home') }}</div>
        </div>
      </router-link>
      <router-link to="/welfare">
        <div class="yuan">
          <div class="btn_icon">
            <svg
              t="1705649826692"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5332"
              width="100%"
              height="100%"
            >
              <path
                d="M664.82176 440.85248l-16.67072-33.81248-16.67072 33.81248a70.67648 70.67648 0 0 1-53.1456 38.62528l-37.33504 5.4272 26.97216 26.3168a70.4512 70.4512 0 0 1 20.33664 62.40256l-6.38976 37.1712 33.36192-17.55136a70.7584 70.7584 0 0 1 65.65888 0l33.42336 17.55136-6.38976-37.10976a70.67648 70.67648 0 0 1 20.2752-62.464l27.0336-26.3168-37.33504-5.4272a70.67648 70.67648 0 0 1-53.12512-38.62528z"
                fill="#727477"
                p-id="5333"
              />
              <path
                d="M807.54688 143.36H412.79488a23.94112 23.94112 0 0 0-23.36768 18.51392 77.0048 77.0048 0 0 1-149.504 0A23.94112 23.94112 0 0 0 216.51456 143.36 155.01312 155.01312 0 0 0 61.44 298.37312v430.81728a155.0336 155.0336 0 0 0 155.09504 155.01312 23.94112 23.94112 0 0 0 23.36768-18.51392 77.0048 77.0048 0 0 1 149.504 0c2.53952 10.8544 12.22656 18.5344 23.38816 18.51392H807.5264A155.0336 155.0336 0 0 0 962.56 729.16992V298.3936A155.01312 155.01312 0 0 0 807.54688 143.36zM363.49952 705.24928a42.2912 42.2912 0 0 1-84.5824 0v-46.75584a42.2912 42.2912 0 0 1 84.5824 0v46.75584z m0-171.13088a42.2912 42.2912 0 0 1-84.5824 0v-46.81728a42.2912 42.2912 0 0 1 84.5824 0v46.81728z m0-171.04896a42.2912 42.2912 0 0 1-84.5824 0v-46.8992a42.2912 42.2912 0 0 1 84.5824 0v46.8992z m466.2272 164.27008l-39.89504 38.62528 9.33888 54.55872a70.4512 70.4512 0 0 1-102.44096 74.3424l-48.90624-25.76384-48.98816 25.78432a70.53312 70.53312 0 0 1-102.35904-74.3424l9.66656-54.74304-39.89504-38.62528a70.61504 70.61504 0 0 1 39.40352-120.29952l54.74304-7.9872 24.49408-49.62304a70.51264 70.51264 0 0 1 126.52544 0l24.49408 49.62304 54.72256 7.9872a70.53312 70.53312 0 0 1 39.09632 120.29952v0.16384z"
                fill="#727477"
                p-id="5334"
              />
            </svg>
          </div>
          <div>{{ $t('h.welfare') }}</div>
        </div>
      </router-link>
      <router-link to="/shopping">
        <div class="yuan">
          <div class="btn_icon">
            <svg
              t="1705649797301"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5197"
              width="100%"
              height="100%"
            >
              <path
                d="M917.07392 408.22784a93.24544 93.24544 0 0 0-30.57664-45.4656 86.91712 86.91712 0 0 0-54.96832-19.10784H660.6848a10.30144 10.30144 0 0 1-9.76896-7.51616l-52.75648-169.1648a92.09856 92.09856 0 0 0-34.69312-48.39424 87.79776 87.79776 0 0 0-50.9952-16.1792c-18.432 0-36.0448 5.59104-50.9952 16.1792-16.384 11.61216-28.2624 28.32384-34.52928 48.47616l-52.8384 169.02144a10.24 10.24 0 0 1-9.6256 7.51616H193.72032a86.016 86.016 0 0 0-54.94784 19.2512 97.42336 97.42336 0 0 0-31.47776 101.02784c5.5296 19.6608 17.05984 36.6592 33.52576 49.152l138.15808 104.59136c3.6864 2.72384 5.20192 7.49568 3.76832 11.93984l-20.8896 67.1744c-16.87552-17.6128-42.12736-17.6128-57.69216-1.4336l-90.112 93.94176a42.8032 42.8032 0 0 0 0 58.69568 38.912 38.912 0 0 0 56.38144 0l65.61792-68.32128-5.9392 19.10784c-6.28736 20.13184-6.144 40.96 0.47104 60.416a92.16 92.16 0 0 0 32.09216 44.2368 87.32672 87.32672 0 0 0 50.3808 18.16576h2.72384c18.90304 0 37.00736-6.22592 52.77696-18.16576l138.24-104.50944a9.97376 9.97376 0 0 1 12.00128 0l137.91232 104.09984c16.384 12.43136 35.49184 18.78016 55.35744 18.16576a87.6544 87.6544 0 0 0 50.3808-18.0224 92.99968 92.99968 0 0 0 32.09216-44.31872 96.4608 96.4608 0 0 0 0.6144-60.33408l-52.77696-169.1648a10.8544 10.8544 0 0 1 3.6864-11.8784l138.4448-104.52992a92.16 92.16 0 0 0 33.5872-49.27488 98.304 98.304 0 0 0-1.024-55.5008v0.12288z"
                fill="#727477"
                p-id="5198"
              />
              <path
                d="M749.89568 268.84096a32.768 32.768 0 0 0 23.47008-9.91232l63.488-68.25984a34.6112 34.6112 0 0 0 0-47.98464 32.62464 32.62464 0 0 0-46.81728 0l-63.56992 68.1984a34.6112 34.6112 0 0 0 0 48.04608 32.768 32.768 0 0 0 23.42912 9.91232z m68.8128-18.98496a34.67264 34.67264 0 0 0 1.024 47.98464c13.1072 13.02528 34.42688 12.4928 46.8992-1.14688l17.46944-16.7936a34.67264 34.67264 0 0 0-1.08544-48.06656 32.768 32.768 0 0 0-46.83776 1.16736l-17.46944 16.85504z"
                fill="#727477"
                p-id="5199"
              />
            </svg>
          </div>
          <div>{{ $t('h.skinsMall') }}</div>
        </div>
      </router-link>
      <router-link to="/recharge">
        <div class="yuan">
          <div class="btn_icon">
            <svg
              t="1705649759819"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4929"
              width="100%"
              height="100%"
            >
              <path
                d="M852.74624 122.88H166.44096C108.544 122.88 61.44 169.7792 61.44 227.38944v46.592c0 74.30144 60.80512 134.79936 135.49568 134.79936h28.50816v61.09184c0 134.90176 99.00032 246.51776 228.59776 267.71456v85.8112h-128a45.58848 45.58848 0 0 0-45.71136 45.4656 45.64992 45.64992 0 0 0 45.71136 45.52704h347.50464a45.58848 45.58848 0 0 0 45.69088-45.50656c0-25.10848-20.48-45.50656-45.69088-45.50656h-128v-85.8112c129.59744-21.0944 228.59776-132.79232 228.59776-267.6736V408.7808h48.29184c74.71104 0 135.49568-60.49792 135.49568-134.79936v-46.592C957.8496 169.7792 910.62272 122.88 852.74624 122.88z m-653.312 194.90816h-2.60096a44.032 44.032 0 0 1-44.09344-43.8272v-46.57152a13.5168 13.5168 0 0 1 13.59872-13.5168h33.09568v103.91552z m495.0016-6.10304l-160.70656 173.48608c-0.08192 0.1024 0 0.2048-0.08192 0.3072l-43.70432 47.2064c-0.1024 0.2048-0.3072 0.2048-0.512 0.4096l-4.89472 5.28384a16.09728 16.09728 0 0 1-22.69184 0.90112l-55.3984-50.8928 0.4096-0.4096-65.1264-59.6992a3.50208 3.50208 0 0 1-0.2048-4.99712 88.12544 88.12544 0 0 1 124.1088-4.99712l3.11296 2.88768 104.79616-113.09056a85.68832 85.68832 0 0 1 120.50432-4.9152 5.8368 5.8368 0 0 1 0.4096 8.51968z m171.90912-37.70368a43.97056 43.97056 0 0 1-44.11392 43.80672h-2.60096v-103.91552h33.11616c7.49568 0 13.59872 6.10304 13.59872 13.5168v46.592z"
                fill="#727477"
                p-id="4930"
              />
            </svg>
          </div>
          <div>{{ $t('h.topup') }}</div>
        </div>
      </router-link>
      <router-link to="/home">
        <div class="yuan">
          <div class="btn_icon">
            <svg
              t="1705649673907"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4794"
              width="100%"
              height="100%"
            >
              <path
                d="M719.0528 929.792H305.02912C249.89696 929.792 204.8 881.90976 204.8 823.296c0-58.59328 45.09696-106.496 100.2496-106.496h413.9008C774.10304 716.8 819.2 764.70272 819.2 823.296c0.1024 58.61376-45.09696 106.496-100.1472 106.496z"
                fill="#727477"
                p-id="4795"
              />
              <path
                d="M245.76 368.64a266.24 266.24 0 1 0 532.48 0 266.24 266.24 0 1 0-532.48 0Z"
                fill="#727477"
                p-id="4796"
              />
            </svg>
          </div>
          <div>{{ $t('h.account') }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    playAudio(){
      this.$store.commit("playAudio");
    },
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 550px) {
  // .nav {
  //   height: 65px !important;
  //   font-size: 10px !important;
  //   position: fixed !important;
  //   bottom: 0 !important;
  // }
  .yuan {
    width: 50px !important;
    height: 50px !important;
  }
  .btn_icon {
    width: 19px !important;
    height: 21px !important;
    margin: 0 auto;
  }
}
.btn_icon {
  width: 27px;
  height: 30px;
  margin: 0 auto;
}
.yuan {
  width: 70px;
  height: 70px;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  &:hover {
    background: rgba(58, 151, 228, 0.1);
  }
}
.nav {
  width: 100%;
  a {
    display: inline-block;
    width: 20%;
    font-weight: bold;
    color: #727477;
    white-space: nowrap;
    &.router-link-exact-active {
      color: #fff;
      .yuan {
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
</style>