<template>
  <div class="main">
    <div class="logo">
      <img src="@/assets/logo/log.png" alt />
    </div>
    <svg @click="handleClose" t="1705470289990" class="icon close" viewBox="0 0 1024 1024" version="1.1" p-id="3432"
      width="28" height="28">
      <path
        d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
        fill="#727477" p-id="3433" />
    </svg>
    <!-- 登录 -->
    <div class="main_center" v-if="status == 'dl'">
      <div class="main_top">{{ $t('h.signIn') }}</div>
      <div class="main_input">
        <input type="text" v-model="form.phoneNumber" :placeholder="$t('h.accountNumber')" />
      </div>
      <div class="main_input">
        <input type="password" :placeholder="$t('h.password')" v-model="form.password" @keyup.enter="handleLogin" />
      </div>
      <div class="main_pwd">
        <div class="main_input_right" @click="
      handleGo('wjmm');
    playAudio();
    ">
          {{ $t('h.forgotPassword') }}
        </div>
      </div>
      <div class="main_center_bottom">
        <div :class="select ? 'select' : ''" @click="handleAgree"></div>
        <div>{{ $t('h.agreeTerms') }}</div>
      </div>
      <div class="main_bottom" @click="handleLogin();playAudio();">
        {{ $t('h.logIn') }}
      </div>
      <div class="main_bottom_no">
        {{ $t('h.noAccount') }}
        <span @click="handleGo('zc');playAudio();">{{ $t('h.signup') }}</span>
      </div>
    </div>
    <div class="main_center" v-if="status == 'wjmm'">
      <div class="main_top">{{ $t('h.forgotPassword') }}</div>
      <div class="main_input">
        <input type="text" v-model="forgetform.phoneNumber" :placeholder="$t('h.phoneMumber')" />
      </div>
      <div class="main_input">
        <input type="text" :placeholder="$t('h.code')" v-model="forgetform.code" />
        <div @click="getyzm();playAudio();" class="yzm">
          {{ $t('h.getCode') }}
        </div>
      </div>
      <div class="main_input">
        <input type="password" :placeholder="$t('h.newPassword')" v-model="forgetform.password" />
      </div>
      <div class="main_input">
        <input type="password" :placeholder="$t('h.confirmPassword')" v-model="forgetform.confirmPassword" />
      </div>
      <div class="main_bottom" @click="handleForget();playAudio();">
        {{ $t('h.confirm') }}
      </div>
      <div class="main_bottom_no">
        {{ $t('h.haveAccout') }}
        <span @click="handleGo('dl');playAudio();">{{ $t('h.signin') }}</span>
      </div>
    </div>
    <div class="main_center" v-if="status == 'zc'">
      <div class="main_top">{{ $t('h.signUp') }}</div>
      <div class="main_input">
        <input type="text" v-model="registerform.phoneNumber" :placeholder="$t('h.phoneMumber')" />
      </div>
      <div class="main_input">
        <input type="text" v-model="registerform.nickName" :placeholder="$t('h.nickname')" />
      </div>

      <div class="main_input">
        <input type="password" :placeholder="$t('h.newPassword')" v-model="registerform.password" />
      </div>
      <div class="main_input">
        <input type="password" :placeholder="$t('h.confirmPassword')" v-model="registerform.confirmPassword" />
      </div>
      <div class="main_input">
        <input type="text" :placeholder="$t('h.code')" v-model="registerform.code" />
        <div @click="getyzm();playAudio();" class="yzm">
          {{ title }}
        </div>
      </div>
      <div class="main_input">
        <input type="text" :placeholder="$t('h.invitationCode')" v-model="registerform.parentInvitationCode" />
      </div>
      <div class="main_center_bottom">
        <div :class="select ? 'select' : ''" @click="handleAgree();playAudio();"></div>
        <div>
          {{ $t('h.agreeTerms') }}
        </div>
      </div>

      <div class="main_bottom" @click="handleRegister();playAudio();">
        {{ $t('h.signUp') }}
      </div>
      <div class="main_bottom_no">
        {{ $t('h.haveAccout') }}
        <span @click="handleGo('dl');playAudio();">{{ $t('h.signin') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogin, getRegister, getcode, changePass } from "../../../api/index";
import browser from "@/utils/brouser";
// getToken
import { setToken } from "../../../api/cookie";
import { getUserInfo } from "../../../api/index";
// import { mapState } from "vuex";
export default {
  data() {
    return {
      disabled: false,
      timer: null,
      second: '',
      registerform: {
        code: "",
        nickName: "",
        parentInvitationCode: "",
        password: "",
        phoneNumber: "",
        confirmPassword: "",
      },
      forgetform: {
        code: "",
        nickName: "",
        parentInvitationCode: "",
        password: "",
        phoneNumber: "",
        confirmPassword: "",
      },
      status: "dl",
      select: false,
      form: {
        code: "",
        nickName: "",
        parentInvitationCode: "",
        password: "",
        phoneNumber: "",
      },
    };
  },
  computed: {
    title() {
      return this.disabled ? `${this.$i18n.t('h.resend')}( ${this.second} ) s` : this.$i18n.t('h.getCode');
    },
  },
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    handleForget() {
      if (this.forgetform.phoneNumber == "") {
        this.$message({
          message: this.$i18n.t('h.pleaseEnterNewPasswordOrPhoneNumber'),
          type: "warning",
          customClass: "log_warning",
        });
        return;
      } else if (this.forgetform.password == "") {
        this.$message({
          message: `${this.$i18n.t('h.password')}!`,
          type: "warning",
          customClass: "log_warning",
        });
      } else if (this.forgetform.password != this.forgetform.confirmPassword) {
        this.$message({
          message: this.$i18n.t('h.passwordsNotMatch'),
          type: "warning",
          customClass: "log_warning",
        });
      } else {
        let data = {
          code: this.forgetform.code,
          confirmPassword: this.forgetform.confirmPassword,
          password: this.forgetform.password,
          phoneNumber: this.forgetform.phoneNumber,
        };
        changePass(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: this.$i18n.t('h.resetPassword'),
              type: "success",
              customClass: "log_success",
            });
            this.status == "dl";
          } else {
            this.$message({
              message: this.$i18n.t('h.incorrectVerificationCode'),
              type: "warning",
              customClass: "log_warning",
            });
          }
        });
      }
    },
    getyzm() {
      let dataform = {};
      if (this.disabled) {
        return false
      }
      if (this.status == "zc") {
        if (this.registerform.phoneNumber == "") {
          this.$message({
            message: this.$i18n.t('h.usernameOrPhonenumber'),
            type: "warning",
            customClass: "log_warning",
          });
          return;
        }
        dataform.type = 1;
        dataform.phoneNumber = this.registerform.phoneNumber;
      } else if (this.status == "wjmm") {
        if (this.forgetform.phoneNumber == "") {
          this.$message({
            message: this.$i18n.t('h.usernameOrPhonenumber'),
            type: "warning",
            customClass: "log_warning",
          });
          return;
        }
        dataform.phoneNumber = this.forgetform.phoneNumber;
        dataform.type = 4;
      }
      getcode(dataform).then((res) => {
        if (res.data.code == 200) {
          let that = this;
          let s = 60; //倒计时间
          if (!that.timer) {
            that.second = s;
            that.disabled = true;
            that.timer = setInterval(() => {
              if (that.second > 0 && this.second <= s) {
                that.second--;
              } else {
                that.disabled = false;
                clearInterval(that.timer);
                this.timer = null;
              }
            }, 1000);
          }
          this.$message({
            message: `${res.data.msg}`,
            type: "success",
            customClass: "log_success",
          });
        }
        if (res.data.code == 500) {
          this.$message({
            message: `${res.data.msg}`,
            type: "warning",
            customClass: "log_warning",
          });
        }
      });
    },
    handle() { },
    handleGo(res) {
      this.status = res;
    },
    handleRegister() {
      if (this.select == false) {
        this.$message({
          message: this.$i18n.t('h.agreeUserAgreement'),
          type: "warning",
          customClass: "log_warning",
        });
      } else {
        if (
          this.registerform.phoneNumber == "" &&
          this.registerform.nickName == ""
        ) {
          this.$message({
            message: this.$i18n.t('h.usernameOrPhonenumber'),
            type: "warning",
            customClass: "log_warning",
          });
          return;
        } else if (this.registerform.password == "") {
          this.$message({
            message: this.$i18n.t('h.password'),
            type: "warning",
            customClass: "log_warning",
          });
        } else if (
          this.registerform.confirmPassword != this.registerform.password
        ) {
          this.$message({
            message: this.$i18n.t('h.notMatchPasswords'),
            type: "warning",
            customClass: "log_warning",
          });
        } else if (this.registerform.code == "") {
          this.$message({
            message: this.$i18n.t('h.pleaseGetCode'),
            type: "warning",
            customClass: "log_warning",
          });
        } else {
          if (this.registerform.parentInvitationCode == "mkcsgo") {
            this.registerform.parentInvitationCode = "";
          }
          getRegister(this.registerform).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: this.$i18n.t('h.registrationSucceeded'),
                type: "success",
                customClass: "info_success",
              });
              this.status = "dl";
            } else {
              this.$message({
                message: `${res.data.msg}`,
                type: "warning",
                customClass: "warning",
              });
            }
          });
        }
      }
    },
    handleLogin() {
      if (this.select == false) {
        this.$message({
          message: this.$i18n.t('h.followingAgreements'),
          type: "warning",
          customClass: "log_warning",
        });
      } else if (this.select == true) {
        if (this.form.phoneNumber == "" && this.form.password == "") {
          this.$message({
            message: this.$i18n.t('h.usernameOrPassword'),
            type: "warning",
            customClass: "log_warning",
          });
          return;
        }

        let data = {
          username: this.form.phoneNumber,
          password: this.form.password,
          equipment: '1'
        };
        if (!browser().mobile) {
          // data.equipment='1'
        } else if (browser.webapp) {
          data.equipment = '2'
        } else {
          data.equipment = '1'
        }
        getLogin(data)
          .then((res) => {
            let code = res.data.code;
            if (code == "200") {
              this.handleClose();
              this.exist = 2;

              setToken(res.data.token);
              this.$store.commit("LOGIN_IS_SHOW", false);
              // this.$router.back()
              // window.history.back()
              this.$message({
                message: this.$i18n.t('h.loginSuccessful'),
                type: "success",
                customClass: "log_success",
              });
              //   this.close();
              //获取用户信息
              getUserInfo().then((res) => {
                if (res.data.code == 200) {
                  this.$store.commit("USER_INFO", res.data.data);
                  this.$bus.$emit("islogin", false);
                  this.$router.push("/");
                  this.$socket.send("login_userId_" + res.data.data.userId);
                }
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
                customClass: "log_warning",
              });
            }
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      }
    },
    handleClose() {
      this.$bus.$emit("close", false);
    },
    handleAgree() {
      this.select = !this.select;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
  height: 100vh;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  .close {
    position: absolute;
    right: 14px;
    top: 21px;
  }

  .logo {
    img {
      width: 170px;
      height: 60px;
      margin-top: 80px;
      margin-bottom: 15px;

      @media (max-width: 550px) {
        width: 170px;
        height: 60px;
        margin-top: 70px;
      }
    }
  }
}

.main_top {
  width: 100%;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  margin-top: 7px;

  @media (max-width: 550px) {
    font-size: 20px;
  }
}

.main_center {
  width: 450px;
  margin: 0 auto;

  .main_input {
    width: 100%;
    position: relative;

    input {
      margin-top: 21px;
      width: 100%;
      height: 45px;
      background: transparent;
      padding: 0 15px;
      border-radius: 35px;
      opacity: 1;
      border: 1px solid #ffffff;
      outline: none;
      font-size: 13px;
      color: #fff;

      @media (max-width: 550px) {
        height: 40px;
      }
    }

    .yzm {
      position: absolute;
      color: #3a97e4;
      right: 20px;
      top: 50%;
      transform: rotateY("50%");
      font-weight: 500;
    }
  }

  .main_pwd {
    width: 100%;
    margin-top: 10px;
    height: 31.5px;
  }

  .main_input_right {
    width: 140px;
    height: 42px;
    background: #101115;
    box-shadow: 0px 0 12px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    opacity: 1;
    color: #ffffff;
    line-height: 42px;
    text-align: center;
    font-size: 13.3px;
    float: right;

    @media (max-width: 768px) {
      // width: 100px;
      height: 30px;
      line-height: 30px;
    }
  }

  .main_center_bottom {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 550px) {
      margin-top: 15px;
    }

    .select {
      background: #3a97e4 !important;
      border: 2px solid #3a97e4 !important;
    }

    div:first-child {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #727477;
      box-sizing: border-box;
      margin-top: 5px;

      @media (max-width: 550px) {
        margin-top: 3px;
        width: 8px;
        height: 8px;
        border: 1px solid #727477;
      }
    }

    div:last-child {
      width: 95%;
      color: #ffffff;
      font-size: 14px;
      text-align: left;
      word-spacing: -2px;

      @media (max-width: 550px) {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 768px){
    width: 80%;
  }
}



.main_center1 {
  margin: 0 auto;
  width: 90%;

  .main_czcg {
    width: 100%;
    height: 182px;
    background: #323436;
    border-radius: 21px 21px 21px 21px;
    opacity: 1;
    padding: 32px;
    margin: 0 auto;

    p {
      font-size: 28px;
    }

    .main_czcg_btn {
      justify-content: space-between;
      margin-top: 35px;

      div:first-child {
        width: 45%;
        height: 50px;
        border-radius: 35px 35px 35px 35px;
        opacity: 1;
        border: 3px solid #727477;
        font-size: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:last-child {
        font-size: 21px;
        width: 45%;
        height: 50px;
        background: rgba(37, 244, 132, 0.2);
        border-radius: 35px 35px 35px 35px;
        opacity: 1;
        border: 3px solid #25f484;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.main_bottom {
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
  height: 54px;
  background-image: url("../../../assets/image/loggin_button.png");
  background-size: 100% 100%;
  color: #ffffff;
  line-height: 54px;
  margin-top: 28px;

  @media (max-width: 550px) {
    height: 45px;
    font-size: 18px;
    line-height: 45px;
    margin-top: 20px;
  }
}

.main_bottom_no {
  margin-top: 16px;
  font-size: 13px;

  @media (max-width: 550px) {
    font-size: 13px;
  }

  span {
    color: #3a97e4;
  }
}
</style>
