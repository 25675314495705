// 英文语言包
export default {
    h: {
        lang: "Englist",
        home:"HOME",
        welfare:"WELFARE",
        skinsMall:"SKINS MALL",
        topup:"TOP UP",
        account: "Account",
        login:"Login",
        register:"Register",
        signIn:"Sign In",
        signUp:"Sign Up",
        prvacyPolicy:"Prvacy Policy",
        aboutItemTakes:"About Item Takes",
        support:"Support",
        userAgreement:"User Agreement",
        steamTradeUrl:"Steam Trade-URL",
        aboutTakeItems:"About Take Items",
        termsAndConditions:"Terms and conditions",
        caseUnBoxing:"Case UnBoxing",
        battles:"Battles",
        roll:"Roll",
        percent:"Percent",
        Login:"Log-in",
        accountNumber:"Please enter your account.",
        password:"Please enter your password",
        forgotPassword:"Forgot Password",
        agreeTerms:"Yes! I am 18 years old.Agree to the Terms of Service&Privacy Policy&AML Policy，committing to responsible consumption.",
        accountRegister:"Don't have an account yet? click on register",
        phoneMumber:"Please enter your phone number",
        nickname:"Please enter your nickname",
        confirmPassword:"Please confirm your password.",
        code:"Please enter the verification code",
        invitationCode:'Please enter the invite code',
        playWithBlindBoxes: "Play with blind boxes",
        rapidCompetition: "Rapid competition",
        pursuingDreamsAndMovingForward: "Pursuing Dreams and Moving Forward",
        gameplayDescription: "Gameplay Description",
        accountBalance: "Account balance",
        recharge: "recharge",
        loginExpired: "Login expired, please log in again",
        loading: "Loading...",
        waitBeforeTry: "Please wait before trying again.",
        reLogin: "Your login session has expired, please re-login.",
        tooFrequent: "Please wait 1 second before trying again, as your actions are too frequent.",
        logIn: "Log In",
        accountUpdate: "Account Update",
        noAccount: "No Account?",
        signup: "Sign Up.",
        getCode: "Get Code",
        newPassword: "Please enter a new password",
        confirm: "Confirm",
        haveAccout: "Have an Account?",
        signin: "Sign In.",
        resend: "Get Again in",
        pleaseEnterNewPasswordOrPhoneNumber: "Please enter a new password or phone number.",
        passwordsNotMatch: "The passwords do not match. Please re-enter!",
        resetPassword: "Password reset succeeded.",
        incorrectVerificationCode: "Incorrect verification code. Please try again.",
        usernameOrPhonenumber: "Please enter your username or phone number.",
        agreeUserAgreement: "Please click to agree to the User Agreement.",
        notMatchPasswords: "Passwords do not match.",
        pleaseGetCode: "Please get the verification code.",
        registrationSucceeded: "Registration successful.",
        followingAgreements: "Please select the following agreements.",
        usernameOrPassword: "Please enter your username or password.",
        loginSuccessful: "Login successful.",
        myBag: "My Bag",
        identityVerification: "Identity Verification",
        completeRealName: "Please perform identity verification first.",
        identityVerificationSuccessful: "Identity verification successful.",
        clickVerify: "You have not completed real name verification. Click to verify now.",
        verificationCompleted: "Identity verified.",
        personalDetails: "Personal Details",
        steamTrading: "Your STEAM trading link is not set up.",
        boundSteamTrading: "Link STEAM Trading URL",
        pleaseSteamTrading: "Please enter your STEAM trading link.",
        steamTradingSuccess: "STEAM Trading Link Bound Successfully",
        steamTradingFail: "STEAM Trading Link Binding Failed",
        bound: "Successfully Bound",
        notBound: "Not Bound",
        gotobind: "Go to Binding",
        gotomodify: "Go to Modify",
        signInPassword: "Sign-In Password",
        myInviteCode: "My Invitation Code",
        bindInvitationCode: "Bind Invitation Code",
        signOut:"Sign Out",
        cancel: "Cancel",
        confirmOk: "Confirm",
        realname:"Please enter your real name.",
        idCard: "Please enter your ID number.",
        passwordModification: "Password Modification",
        oldPassword: "Please enter your old password.",
        pleaseNewPassword: "Please enter your new password.",
        passwordTips: "Password length must be between 5 and 20 characters.",
        yourInvitationCode: "Please enter your invitation code.",
        invitationTips: "Invitation code can only be bound once. Please proceed carefully.",
        dailyCheckIn: "Daily Check-In",
        dailyTips: "Check in once daily and receive a random amount between 0.1 and 5 coins.",
        checkIn: "Check In",
        signOutTips: "Are you sure you want to log out?",
        chooseAvatar: "Choose Avatar",
        vipUpgradeCriteria: "VIP Upgrade Criteria",
        vipLevel: "VIP Level",
        modificationSuccessful: "Modification Successful",
        invitationCodeBindingSuccessful: "Invitation Code Binding Successful",
        passwordChangeTips: "Password change successful. Please log in again.",
        passwordChangeErrorTips: "The two password entries do not match. Please re-enter.",
        goldenListAchievement: "Golden List Achievement",
        welfareCenter: "Welfare Center",
        promotionalBenefits: "Promotional Benefits",
        spendingLeaderboard: "Spending Leaderboard",
        today: "Today",
        yesterday: "Yesterday",
        lastSevenDays: "Last Seven Days",
        explanation: "Explanation",
        rewardsExplanation: "Leaderboard Rewards Explanation",
        rank: "Rank",
        reward: "Reward",
        rewardTips:"The top 10 players by daily spending rewards will be announced the next day at [00:00].",
        rewardTips2: "The system automatically sends spending rewards to player accounts.",
        ranking: "Ranking",
        player: "Player",
        spendOCoins: "Spend O Coins",
        spendGCoins: "Spend G Coins",
        total: "Total",
        yetRanked: "You have not yet made it to the list",
        yourRank: "Your Rank",
        shippingLeaderboard: "Shipping Leaderboard",
        itemImage: "Item Image",
        itemName: "Item Name",
        itemValue: "Item Value",
        promotionEarnings: "Promotion Earnings",
        tomorrowEstimate: "Tomorrow's Estimate",
        myTeam: "My Team",
        view: "View",
        myInviter: "My Inviter",
        earningsDetails: "Earnings Details",
        time: "Time",
        type: "Type",
        earnings: "Earnings",
        Nickname: "Nickname",
        amount: "Amount",
        todaySpending: "Today's Spending",
        yesterdaySpending: "Yesterday's Spending",
        noMoreAvailable: "No More Data Available",
        back: "Back",
        retrieveTutorial: "Retrieve Tutorial",
        websiteRechargeTutorial: "Website Recharge Tutorial",
        changeAvatar: "Change Avatar",
        changeNickname: "Change Nickname",
        changePhoneNumber: "Change Phone Number",
        selectFile: "Select File",
        sendVerificationCode: "Send Verification Code",
        validPhoneNumber: "Please enter a valid phone number.",
        totalNumberOfCosmetics: "Total Number of Cosmetics",
        totalValueOfCosmetics: "Total Value of Cosmetics",
        totalNumberOfSelectedCosmetics: "Total Number of Selected Cosmetics",
        totalValueOfSelectedCosmetics: "Total Value of Selected Cosmetics",
        defaultSorting: "Default Sorting",
        lowToHigh: "Sort by Price: Low to High",
        highToLow: "Sort by Price: High to Low",
        pleaseCosmeticName: "Please enter the cosmetic name.",
        extract: "Extract",
        decompose: "Decompose",
        selectAll: "Select All",
        selected: "Selected",
        backpackTips: "Are you sure you want to empty your backpack?",
        decomposeTips: "Are you sure you want to decompose?",
        extractBackpackTips: "Are you sure you want to extract all items from your backpack?",
        extractWantTips: "Are you sure you want to extract",
        extractWantTimes: "items?",
        cosmeticBeforeProceeding: "Please select a cosmetic before proceeding",
        decompositionSuccessful: "Decomposition Successful",
        pickupInvoice: "Pickup Invoice",
        incomeAndExpenditureDetails: "Income and Expenditure Details",
        rechargeDetails: "Recharge Details",
        decompositionDetails: "Decomposition Details",
        extractionComplete: "Extraction Complete",
        decomposed: "Decomposed",
        frozen: "Frozen",
        applyForPickup: "Apply for Pickup",
        awaitingShipment: "Awaiting Shipment",
        shippingInProgress: "Shipping in Progress",
        coin: "Coin",
        purpose: "Purpose",
        status: "Status",
        consumption:"Consumption",
        income: "Income",
        other: "Other",
        paymentAmount: "Payment Amount",
        paymentMethod: "Payment Method",
        paymentOrderNumber: "Payment Order Number",
        paymentTime: "Payment Time",
        cardPin: "Card PIN",
        alipay: "Alipay",
        weChat: "WeChat",
        redemptionInstructions: "Redemption Instructions",
        yuan: "Yuan",
        pleaseCardPin: "Please Enter the Card PIN",
        payment: "Payment",
        alipayTips:"After the order is generated, please use Alipay to scan the QR code to complete the payment.",
        payMoneyTips: "Please select the amount before making the payment",
        agreeContentTips: "Please agree to the User Agreement, Privacy Policy, and other relevant content first.",
        rewardsStore: "Rewards Store",   
        comprehensiveSorting: "Comprehensive Sorting",
        priceInDescendingOrder: "Price in Descending Order",
        priceInAscendingOrder: "Price in Ascending Order",
        valueFiltering: "Value Filtering",
        PointsRedemption: "Points Redemption",
        exchange: "Exchange",
        category:"Category",
        quality:"Quality",
        redemptionSuccessful: "Redemption Successful",
        default: "Default",
        noCosmeticsAvailable: "No Cosmetics Available",
        allLoaded: "All Loaded",
        latest: "Latest",
        blindBoxCategory: "Blind Box Category",
        battleLobby: "Battle Lobby",
        luckyKing: "Lucky King",
        unluckyChief: "Unlucky Chief",
        createRoom: "Create Room",
        inProgress: "In Progress",
        ended:"Ended",
        myParticipation: "My Participation",
        history: "History",
        pending: "Pending",
        numberOfRounds: "Number of Rounds",
        enter: "Enter",
        spectate: "Spectate",
        createSimilarRoom: "Create Similar Room",
        copyShareLink: "Copy Share Link",
        waitingJoin: "Waiting to Join",
        consumeTips: "Are you sure you want to consume",
        join: "Join",
        create: "Create",
        createdSuccessfully:"Created Successfully",
        gameStart: "Game Start",
        copySuccessful: "Copy Successful",
        browserTips: "Your browser version is too low. Please upgrade your browser.",
        informationTips: "Failed to Retrieve User Information. Please Log In Again.",
        official: "Official",
        streamer: "Streamer",
        pleaseRollTips: "Please Enter the ROLL Room Name",
        viewRoom:"View Room",
        joinRoom:"Join Room",
        noMoreAvailableRoll: "No More ROLL Rooms Available",
        pullDown: "Pull Down to Load More",
        favoriteCosmetic: "Select Your Favorite Cosmetic",
        dreamChasing: "Dream Chasing Success Rate",
        randomlyObtain:"Randomly Obtain a Cosmetic",
        pleaseSelectCosmetic: "Please Select a Cosmetic",
        cost: "Cost",
        tryYourLuck: "Try Your Luck",
        priceRange: "Price Range",
        minimumValue: "Minimum Value",
        maximumValue: "Maximum Value",
        filter: "Filter",
        congratulationsWinning: "Congratulations on Winning",
        historicalDrop: "Historical Drop",
        streamerDrop: "Streamer Drop",
        obtain: "Obtain",
        value: "Value",
        result: "Result",
        successRate: "Success Rate",
        success: "Success",
        failure: "Failure",
        rechargeTips: "Insufficient Balance. Please Recharge.",
        failedTips: "Failed, Got Nothing",
        cosmeticFirstTips: "Please Select a Cosmetic First",
        gameplayInstructions: "Gameplay Instructions",
        iKnow: "I Know",
        copyright: "Copyright",
        participationRequirements: "Participation Requirements",
        allUsersCanParticipate: "All Users Can Participate",
        rechargeSinceOpeningDate:"Recharge Since Opening Date",
        participate: "Can Now Participate",
        roomDescription: "Room Description",
        noneAtTheMoment: "None at the Moment",
        drawTime: "Draw Time",
        prizePool: "Prize Pool",
        listOfWinners: "List of Winners",
        participants: "Participants",
        breakDownItem: "Break Down Item",
        putIntoBackpack: "Put into Backpack",
        breakDownAll: "Break Down All",
        memberDrop: "Member Drop",
        boxContains: "Box Contains",
        openImmediately: "Open Immediately",
        pleaseEnterROLLRoomPassword: "Please Enter the ROLL Room Password",
        joinedSuccessfully: "Joined Successfully. Please Wait for the Draw.",
        redeemRedPacket: "Redeem Red Packet",
        enterCdk: "Please Enter the CDK",
        claimedSuccessfully: "Claimed Successfully",
        congratulationsObtaining: "Congratulations on Obtaining",
        downloadAPP: "Download APP",
        android: "Android",
        siteMessage: "Site Message",
        allRead: "All Read",
        deleteAll: "Delete All",
        kindReminder: "Kind Reminder",
        confirmAction: "Confirm Action?",
        numberPeopleRoom: "Number of People in the Room",
        roomCategory: "Room Category",
        mode: "Mode",
        typesBlindBoxes: "Types of Blind Boxes",
        valueBlindBox: "Value of Blind Box",
        maximum6: "Maximum 6 Blind Boxes Can Be Selected",
        maximumLimit: "Selection Exceeds Maximum Limit",
    },
    b: {
        "隐私协议":"Prvacy Policy",
        "关于我们":"About Item Takes",
        "帮助中心":"Support",
        "用户协议":"User Agreement",
        "交易链接更换绑定":"Steam Trade-URL",
        "饰品取回相关":"About Take Items",
        "条款和条例":"Terms and conditions",
        "交易链接更换解绑定":"Trade Link Replacement and Unbinding",
        "合规声明":"Compliance Statement",
        "充值相关": "Recharge Related",
        "邀请码相关":"Invitation Code Related",
        "匕首":"Dagger",
        "手枪":"Pistol",
        "步枪":"Rifle",
        "微型冲锋枪":"Submachine Gun",
        "重型武器":"Heavy Weapon",
        "手套":"Gloves",
        "印花":"Pattern",
        "其他":"Other",
        "默认":"Default",
        "StatTrak™": "StatTrak™",
        "★":"★",
        "★ StatTrak™":"★ StatTrak™",
        "普通": "Normal",
        "闪耀":"Shimmering",
        "金色":"Golden",
        "全息":"Holographic",
        "闪亮":"Shiny",
        "纪念品":"Souvenir",
        "欢迎来到CSGOWE、本网站禁止向未成年人提供服务！": "Welcome to CSGOWE. This website does not provide services to minors."
    }
}