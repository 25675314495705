<template>
  <div id="app" class="app">
    <div
      class="bg zuo"
      :style="{ width: (screenWidth - 525) / 2 + 'px' }"
    ></div>
    <div class="content">
      <headerTop id="headerTop"></headerTop>
      <!-- animate__animated animate__fadeInLeft -->
      <router-view class="appview" v-if="!$route.meta.iskeep"></router-view>
      <keep-alive>
        <router-view class="appview" v-if="$route.meta.iskeep"></router-view>
      </keep-alive>
      <!-- <login v-if="show" class="App_login"></login> -->
      <WipeSidebar :slider="slider" class="cesildera"></WipeSidebar>
      <slider v-if="slider" class="App_slider"></slider>
      <illustrate v-if="isillustrate" class></illustrate>

      <bottomUser id="bottomUser"></bottomUser>
    </div>
    <div
      class="bg you"
      :style="{ width: (screenWidth - 525) / 2 + 'px' }"
    ></div>
  </div>
</template>
<script></script>
<script>
import login from "@/views/Home/common/login.vue";
import slider from "@/components/sliderUser.vue";
import { getToken } from "@/api/cookie";

import headerTop from "@/components/headerTop.vue";
import bottomUser from "@/components/bottomUser.vue";
import WipeSidebar from "@/components/WipeSidebar.vue";
import store from "@/vuex/store";
export default {
  data() {
    return {
      he: 0,
      // width: window.innerWidth
      show: false,
      slider: false,
      isillustrate: false,
      w: document.documentElement.clientWidth,
      screenWidth: document.body.clientWidth, // 屏幕宽度
    };
  },
  components: {
    login,
    headerTop,
    slider,
    bottomUser,
    WipeSidebar,
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    this.$nextTick(() => {
      console.log("播放音乐");
      this.$store.commit("playAudio");
    });
    console.log("window.history.state", window.history.state);
    if (window.history.state && window.history.state.title) {
      if (this.$store.state.LOGIN_IS_SHOW) {
        return;
      } else {
        window.history.back();
      }
    }
    let aa = document.getElementById("headerTop").offsetHeight;
    let bb = document.getElementById("bottomUser").offsetHeight;
    this.he = aa + bb;
    console.log("路由信息", this.$route);

    // this.getToken();

    this.$bus.$on("close", (data) => {
      this.show = data;
      if (!data) {
        // console.log(this.$router);
        this.$router.back();
      }
      // this.$router.back()
      // window.history.back()
    });
    this.$bus.$on("is_illustrate", (data) => {
      this.isillustrate = data;
    });
    this.$bus.$on("sliderbtn", (data) => {
      this.slider = data;
    });
  },
  methods: {
    getToken() {
      if (getToken()) {
        this.show = false;
        this.$store.commit("LOGIN_IS_SHOW", false);

        this.$bus.$emit("islogin", this.show);
      } else {
        setTimeout(() => {
          this.$router.push("/login");
          this.show = true;
          this.$bus.$emit("islogin", this.show);
          this.$store.commit("LOGIN_IS_SHOW", true);
        }, 500);
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
#bottomUser {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 91px;
  left: 0;
  background-color: #101115;
  font-size: 14px;
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    height: 65px !important;
    font-size: 10px !important;
    position: fixed !important;
    bottom: 0 !important;
  }
}
.App_slider {
  position: absolute;
  right: 15px;
  top: 30%;
  animation: slider 0.3s linear;
}
@keyframes slider {
  0% {
    right: -72px;
  }
  75% {
    right: 18px;
  }
  100% {
    right: 15px;
  }
}
.App_login {
  animation: mymove 0.3s linear;
}
@keyframes mymove {
  0% {
    top: -10px;
    opacity: 0.7;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.cesildera {
  position: absolute;
  right: 30px;
  bottom: 200px;
}
.appview {
  overflow-y: scroll;
  position: relative;
  height: 100vh;
  padding-bottom: 200px;
}
.appview::-webkit-scrollbar {
  display: none;
}
.appview::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  opacity: 0.2;
}
.appview::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.appview::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
@media (min-width: 768px){
  // .appview {
  //   padding-bottom: 120px;
  // }
}
.content {
  text-align: center;
  margin: 0 auto;
  color: #fff;
  height: 100vh;
  width: 100vw;
  position: relative;
  font-size: 1rem;
  background: #181a1c;
  z-index: 2;
  // max-width: 525px;
  // max-width: 1200px;
}
.zuo {
  background: url("../public/1.jpg") no-repeat right center;
  background-size: cover;
  position: absolute;
  left: 0;
  height: 100vh;
}
.you {
  background: url("../public/you.jpg") no-repeat left center;
  background-size: cover;
  position: absolute;
  right: 0;
  height: 100vh;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Microsoft Yahei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  height: 100vh;
  width: 100vw;
  position: relative;
  font-size: 1rem;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}
img {
  vertical-align: middle;
}
svg {
  display: block;
  margin: auto;
}
</style>
