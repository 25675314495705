import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/vuex/store";
import "@/api/scoket";
// 国际化语言包
import { i18n } from './lang/index' //国际化

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/display.css';

import "@/assets/font/iconfont.css";
import "animate.css";

import "@/assets/style/common.scss";
import "@/assets/style/success.scss";
import money from "@/components/common/money.vue";
import integral from "@/components/common/integral.vue";
import boxicon from "@/components/common/boxicon.vue";
import handleback from "@/components/common/back.vue";
import plusreduce from "@/components/common/plusreduce.vue";
import illustrate from "@/components/illustrate.vue";
// import vueSeamlessScroll from "vue-seamless-scroll";
import { socket } from "socket.io";
import VueAgile from 'vue-agile'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import toastRegistry from './views/toast/index'
Vue.prototype.$video = Video
// Vue.use(vueSeamlessScroll);

Vue.use(ElementUI, {
  i18n: (key,value) => i18n.t(key, value) 
});
Vue.use(ElementUI);
Vue.prototype.$socket = socket
Vue.component("money", money);
Vue.component("integral", integral);
Vue.component("handleback", handleback);
Vue.component("plusreduce", plusreduce);
Vue.component("boxicon", boxicon);
Vue.component("illustrate", illustrate);
Vue.config.productionTip = false;
Vue.use(VueAgile)
Vue.use(toastRegistry)
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};


// 尝试从本地缓存获取已保存的语言设置
const savedLocale = localStorage.getItem('locale');
const userLanguage = (savedLocale && savedLocale.length > 1) ? savedLocale : navigator.language || navigator.userLanguage;
// 设置初始语言
i18n.locale = userLanguage.includes('zh') ? 'zh' : 'en';

new Vue({
  router,
  store,
  i18n, //使用国际化
  beforeCreate() {
    // Vue.prototype.$ws=ws
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
}).$mount("#app");
